<template>
  <QPage padding>
    <div class="column  flex-center">
      <QCard
        class="br-25 q-mb-md"
        style="width:100%; max-width:599px;"
      >
        <HCTabs
          v-model="tabModel"
          :options="tabsOptions"
        />
        <!-- Login -->
        <QCardSection v-if="tabModel === 'login'">
          <div class="text-h6 text-center q-mb-md">
            Heureux de vous revoir !
          </div>
          <QForm
            class="full-width q-px-md"
            @submit="authenticate"
          >
            <QInput
              ref="loginUsername"
              v-model="login.username"
              class="full-width"
              type="email"
              :label="$t('form.email')"
              outlined
              required
              lazy-rules
            />
            <QInput
              ref="loginPassword"
              v-model="login.password"
              class="q-mt-md"
              outlined
              :label="$t('form.password')"
              :type="showLoginPwd ? 'text' : 'password'"
              required
              :minlength="8"
              lazy-rules
            >
              <template #append>
                <QIcon
                  :name="showLoginPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="showLoginPwd = !showLoginPwd"
                />
              </template>
            </QInput>
            <div class="text-caption text-center q-mt-sm q-mb-md">
              <HcButton
                :to="{ name: 'passwordLost' }"
                variant="flat"
                label="authentication.lost_password_button"
              />
            </div>
            <div
              v-if="showErrorMsg"
              class="text-negative text-center q-mt-md q-mb-sm"
            >
              <AppContent path="authentication.error.incorrect" />
            </div>

            <div class="flex flex-center q-mb-sm">
              <HcButton
                label="authentication.log_in_button"
                :disable="loading"
                :loading="loading"
                type="submit"
              />
            </div>
          </QForm>
        </QCardSection>

        <!-- Register -->
        <QCardSection v-else-if="tabModel === 'signup'">
          <div class="text-h6 text-center q-mb-md">
            Bienvenue sur HappyCab !
          </div>
          <QForm
            ref="registerForm"
            class="full-width q-px-md"
            @submit="register"
          >
            <QField
              v-model="signup.roles"
              :rules="[val => !!val && val.length]"
              lazy-rules
              borderless
            >
              <div class="full-width text-center">
                <AppContent
                  path="user.select_user_group_helper"
                  class="text-weight-bold q-mb-md"
                />
                <div class="q-gutter-x-md">
                  <HcButton
                    :variant="$_.get(signup, 'roles', []).includes('client') ? 'primary' : 'outlined'"
                    label="authentication.company_signup"
                    @click="$_.set(signup, 'roles', ['client'])"
                  />
                  <HcButton
                    :variant="$_.get(signup, 'roles', []).includes('applicant') ? 'primary' : 'outlined'"
                    label="authentication.applicant_signup"
                    @click="$_.set(signup, 'roles', ['applicant'])"
                  />
                </div>
              </div>
            </QField>
            <QInput
              v-model="signup.firstname"
              :label="$t('user.firstname_label')"
              :rules="[requiredField]"
              debounce="500"
              lazy-rules
              outlined
            />
            <QInput
              v-model="signup.lastname"
              :label="$t('user.lastname_label')"
              :rules="[requiredField]"
              debounce="500"
              lazy-rules
              outlined
            />
            <QInput
              ref="signupUsername"
              v-model="signup.username"
              class="full-width q-mb-lg"
              type="email"
              :label="$t('form.email')"
              :rules="[requiredField, isValidEmailOrEmpty, checkEmailAvailable]"
              outlined
              required
              lazy-rules
            />
            <QField
              v-model="signup.metadata._private.phone"
              borderless
              :rules="[val => !!val || 'Votre saisie est incorrecte']"
              lazy-rules
            >
              <PhoneInput
                :form-object="signup"
                :step="{
                  field: 'metadata._private.phone',
                  label: 'user.phone',
                }"
                class="full-width"
                light
                @stepResult="handlePhoneInput($event)"
              />
            </QField>
            <QField
              v-model="signup.passwordValid"
              borderless
              :rules="[val => !!val || 'Votre saisie est incorrecte']"
              lazy-rules
            >
              <PasswordInput
                light
                class="full-width"
                :form-object="signup"
                :step="{ field: 'password' }"
                @stepResult="signup = $_.merge(signup, $_.set($event.value, 'passwordValid', $event.canGoNext))"
              />
            </QField>

            <QField
              :model-value="$_.get(signup, 'platformData._private.cguAccepted', false)"
              :rules="[requiredField]"
              borderless
              @update:model-value="$_.set(signup, 'platformData._private.cguAccepted', $event)"
            >
              <template #control>
                <HcCheckbox
                  :model-value="$_.get(signup, 'platformData._private.cguAccepted', false)"
                  @update:model-value="$_.set(signup, 'platformData._private.cguAccepted', $event)"
                >
                  <div class="flex items-center no-wrap">
                    <AppContent
                      class="cursor-pointer"
                      path="form.application.accept_CGU"
                    />
                    <HCButton
                      icon="uil:external-link-alt"
                      @click="openCGU"
                    />
                  </div>
                </HcCheckbox>
              </template>
            </QField>

            <AppContent
              v-if="$_.get(signup, 'roles', []).includes('client')"
              path="authentication.free_reminder"
              class="text-caption text-center text-grey-5 q-mb-md"
            />

            <div class="flex flex-center q-mb-sm">
              <HcButton
                label="authentication.sign_up_button"
                :disable="loading || (!$_.get(signup, 'platformData._private.cguAccepted', false) || !$_.get(signup, 'passwordValid', false) || !$_.get(signup, 'metadata._private.phone', false))"
                :loading="loading"
                type="submit"
                @click="$refs.registerForm.submit()"
              />
            </div>
          </QForm>
        </QCardSection>
      </QCard>

      <!-- Bottom buttons and dialogs -->
      <div class="flex flex-center q-gutter-x-md q-my-lg">
        <HcButton
          label="layouts.drawer.help"
          tooltip="authentication.faq_button"
          @click="faqDialog = true"
        />
        <HcButton
          label="layouts.drawer.support"
          tooltip="authentication.help_button"
          @click="supportDialog = true"
        />
      </div>
      <QDialog
        v-model="faqDialog"
        maximized
        @hide="faqDialog = false"
      >
        <AccountCard
          path="layouts.drawer.help"
          icon="uil:box"
          style="min-width:80vw; min-height:80vh"
        >
          <template #header-right>
            <HCButton
              v-close-popup
              is-close
            />
          </template>
          <QToolbar>
            <div class="text-h5">
              🛟
            </div>
            <QToolbarTitle class="text-weight-bold">
              Centre d'aide
            </QToolbarTitle>
          </QToolbar>
          <QSeparator />
          <QCardSection horizontal>
            <QCardSection
              v-if="helpRootEntry"
              class="col-3"
            >
              <QTree
                :nodes="helpRootEntry.fields.structure"
                accordion
                node-key="label"
                class="text-no-wrap"
              >
                <template #default-header="prop">
                  <div
                    :class="{ 'cursor-pointer': prop.node.isEntry }"
                    @click="prop.node.isEntry ? selectEntry(prop.node.slug) : ''"
                  >
                    {{ prop.node.label }}
                  </div>
                </template>
              </QTree>
            </QCardSection>
            <QSeparator vertical />
            <QCardSection class="col">
              <MarkdownNoSsr
                v-if="selectedHelpEntry && !loadingHelp"
                :content="selectedHelpEntry.fields.content"
              />
              <div
                v-else
                class="q-ma-lg q-pa-md"
              >
                <HCLoading inner />
              </div>
            </QCardSection>
          </QCardSection>
        </AccountCard>
      </QDialog>
      <QDialog
        v-model="supportDialog"
        maximized
        @hide="supportDialog = false"
      >
        <AccountCard
          path="layouts.drawer.support"
          icon="uil:comment-question"
          style="min-width:80vw; min-height:80vh"
        >
          <template #header-right>
            <HCButton
              v-close-popup
              is-close
            />
          </template>
          <iframe
            src="https://forms.clickup.com/f/4ay9g-7988/9UYMK44D6TFRCSYRNO"
            loading="lazy"
            title="i"
            onwheel=""
            width="100%"
            height="100%"
            style="background: transparent; border: 0px solid #ccc; min-height: inherit; height: calc(100vh - 74px)"
          />
        </AccountCard>
      </QDialog>
    </div>
  </QPage>
</template>

<script>
import HCTabs from 'hc-core/components/common/hc-tabs'
import ValidationMixins from 'hc-core/mixins/validation.js'
import { base64Decoder } from 'hc-core/composables/routing'
import { capitalizeFirstLetter } from 'hc-core/composables/misc.js'
import PhoneInput from 'hc-core/components/forms/phone-input'
import PasswordInput from 'hc-core/components/forms/password-input'

// Help
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { MarkdownNoSsr } from 'hc-core/components/tools/markdown-no-ssr'

export default {
  components: {
    HCTabs,
    PhoneInput,
    PasswordInput,
    MarkdownNoSsr, // Help
  },
  mixins: [ValidationMixins],
  setup () {
    const store = useStore()
    const route = useRoute()
    const loadingHelp = ref(true)
    const helpRootEntry = ref(null)
    const selectedHelpEntry = ref(null)
    const helpEntries = computed(() => {
      return store.state?.content?.help?.results ?? []
    })

    const onMountedSelect = async () => {
      try {
        loadingHelp.value = true
        // load only once
        if (!helpEntries.value || !helpEntries.value.length) {
          await store.dispatch('content/list', {
            collection: 'help',
            nbResultsPerPage: 50
          })
        }
        const rootEntry = helpEntries.value.find(entry => entry.name === 'help')
        if (rootEntry) helpRootEntry.value = rootEntry
        selectEntry(route.params.slug || route.name)
      } catch (error) {
      } finally {
        loadingHelp.value = false
      }
    }

    const selectEntry = (slug) => {
      const matchingEntryByName = helpEntries.value.find(entry => entry.name === slug)
      const matchingEntryByRoute = helpEntries.value.find(entry => (entry.fields?.routes ?? []).includes(slug))
      selectedHelpEntry.value = matchingEntryByName ?? matchingEntryByRoute ?? selectedHelpEntry.value
      if (!selectedHelpEntry.value && helpRootEntry.value) selectedHelpEntry.value = helpRootEntry.value
    }

    return {
      loadingHelp,
      helpRootEntry,
      selectedHelpEntry,
      helpEntries,
      onMountedSelect,
      selectEntry
    }
  },
  data () {
    return {
      // Login
      login: {
        username: '',
        password: ''
      },
      showErrorMsg: false,
      showLoginPwd: false,

      // Signup
      signup: {
        firstname: '',
        lastname: '',
        username: '',
        password: '',
        metadata: {
          _private: {}
        }
      },

      // Page building
      loading: false,
      faqDialog: false,
      supportDialog: false,
      tabModel: 'login',
      tabsOptions: [
        { label: this.$t('authentication.log_in_button'), name: 'login' },
        { label: this.$t('authentication.sign_up_button'), name: 'signup' },
      ],
    }
  },
  created () {
    const hasQuery = this.$_.get(this.$route, 'query', false)
    const redirectFromName = this.$_.get(this.$route, 'redirectedFrom.name', false)
    if (['signupApplicant', 'signupCompany'].includes(redirectFromName)) {
      this.tabModel = 'signup'
      this.signup.roles = [(redirectFromName === 'signupApplicant') ? 'applicant' : 'client']
    } else if (this.$_.get(hasQuery, 'tab', false)) {
      this.tabModel = this.$_.get(hasQuery, 'tab', false)
      const hasQueryRole = this.$_.get(hasQuery, 'role', false)
      if (hasQueryRole && ['applicant', 'client'].includes(hasQueryRole)) this.signup.roles = [hasQueryRole]
    }
  },
  async mounted () {
    await this.onMountedSelect()
  },
  methods: {
    async authenticate () {
      this.loading = true
      try {
        // Check email
        this.$refs.loginUsername.validate()
        this.showErrorMsg = false
        if (this.$refs.loginUsername.hasError) return

        // Dispatch
        await this.$store.dispatch('auth/login', this.login)

        // Redirect
        this.notifySuccess('authentication.log_in_success')
        this.$router.push(
          this.$_.get(this.$route, 'query.redirect', false) ? base64Decoder(this.$route.query.redirect) : { name: 'dashboard' }
        )

        // Clear form
        this.login = { username: '', password: '' }
        this.showErrorMsg = false
        if (this.$refs.loginUsername) this.$refs.loginUsername.resetValidation()
        if (this.$refs.loginPassword) this.$refs.loginPassword.resetValidation()
      } catch (e) {
        if (e.statusCode === 403) this.showErrorMsg = true
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    async register () {
      this.loading = true
      try {
        if (!this.$_.get(this.signup, 'platformData._private.cguAccepted', false) || !this.$_.get(this.signup, 'passwordValid', false) || !this.$_.get(this.signup, 'metadata._private.phone', false)) {
          this.loading = false
          return
        }

        // Setting up some properties
        const user = this.$_.omit(this.signup, ['passwordValid'])
        this.$_.set(user, 'email', user.username)
        this.$_.set(user, 'lastname', capitalizeFirstLetter(user.lastname))
        this.$_.set(user, 'firstname', capitalizeFirstLetter(user.firstname))

        const signupResult = await this.$store.dispatch('auth/signup', { user })
        if (signupResult) {
          if (signupResult.roles.includes('applicant')) {
            this.$router.push({ name: 'signupApplicantComplete' })
          } else this.$router.push({ name: 'signupCompanyComplete' })
        } else this.notifyError()
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    openCGU () {
      window.open(this.$web({ path: '/cgu' }), '_blank')
    },
    handlePhoneInput (event) {
      if (event.canGoNext) this.signup = this.$_.merge(this.signup, event.value)
    }
  }
}
</script>
